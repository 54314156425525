<template>
    <div>
        <TableList
            :columns="columns"
            :form_data_seo="form_data_seo"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            :is_page="false"
			rowKey="id"
            ref="list"
        >
			<template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加客户类型</a-button>
            </template>
            <template slot="status" slot-scope="data">
				<a-tag
                    :color="data.text == 0 ? config.color.error : config.color.primary"
                    v-for="item in status_list"
                    :key="item.key"
                    v-show="item.key == data.text"
                >{{item.value}}</a-tag>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="edit(data.record,'add')">新增子类型</a>
				<a-divider type="vertical" />
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible"></EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { groupList, changeGroup } from '@/api/customer'
const columns = [
    {
        title: "客户类型名称",
        dataIndex: "name",
	},
	{
        title: "ID",
        dataIndex: "id",
    },
	{
	    title: "排序",
	    dataIndex: "sort",
	},
    {
        title: "客户数",
        dataIndex: "num",
    },
    {
        title: "状态",
		dataIndex: "status",
		scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "备注",
        dataIndex: "remark",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];


export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
			get_table_list: groupList,
			config:this.$config,
			columns,
			status_list:this.$config.status_list,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "name",
                        title: "客户类型名称",
                        options: {}
                    },
                    {
                        type: "select",
                        name: "select",
                        title: "状态",
                        options: {},
                        list: this.$config.status_list,
                    }
                ],
                ...this.$config.form_data_seo
            },
            form_data: {
                title: "添加类型名称",
                list: [
					{
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
					},
					{
                        type: 'text',
                        name: 'pid',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: "text",
                        name: "name",
                        title: "客户类型",
                        options: {}
					},
					{
                        type: 'radio',
                        name: 'status',
                        title: '状态',
                        list: this.$config.status_list,
                        options: {},
                    },
					{
					    type: 'text',
					    name: 'sort',
						 title: '排序',
					    options: {},
					},
                    {
                        type: "text",
                        name: "remark",
                        title: "备注",
                        options: {}
                    }
                ]
            }
        };
    },
    methods: {
        add() {
			this.form_data.title = "添加"
            this.form_data.list.forEach(item => {
                item.options.initialValue = '';
            })
            this.visible = true;
        },
        edit(data,type = 'edit') {
			
			if(type == 'add'){
				this.form_data.title = "添加"
				this.form_data.list.forEach(item => {
					if(item.name == 'pid'){
						item.options.initialValue = data['id'];
					}else{
						item.options.initialValue = '';
					}
				})
			}else{
				this.form_data.title = "编辑"
				this.form_data.list.forEach(item => {
					item.options.initialValue = data[item.name];
				})
			}
            
            this.visible = true;
		},
		submit(e) {
			if(!e.values.id){
				e.values.id = '';
			}
            changeGroup({
                data: e.values,
                info: true,
            }).then(res=>{
				this.visible = false;
				this.$refs.list.get_list();
			})
        }
        
    }
};
</script>

